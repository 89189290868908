.subsite {
    .header {
        //box-shadow: 1px 1px 20px rgba(48, 58, 114, 0.05);
        align-items: stretch;
        &__logo {
            display: flex;
            align-items: center;        
        }
        &__right {
            background-color: #efefef;
            display: flex;
            flex-direction: column;
            justify-content: center;  
            @include mq($until: 1400px) {
                padding-left: 20px;
            }
            @include mq($until: lg) {
                padding-left: 10px;
                margin-left: 10px;
            }
            @include mq($until: md) {
                background-color: unset;
            }
        }
    }
    &__title {
        h1 {
            margin-top: 80px;
            position: relative;
            display: inline-block;
            color: #fff;
            transition: ease 0.3s all;
            background-color: $color-main;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-right: 30px;
            border-right: 10px solid $color-extra;
            border-left: 0 solid $color-main;
            font-size: rem(45px);
            line-height: rem(45px);
            font-weight: 600;
            @include mq($until: 1400px) {
                font-size: rem(35px);
                line-height: rem(35px);
            }
            @include mq($until: md) {
                margin-top: 40px;
                font-size: rem(25px);
                line-height: rem(25px);
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 20px;
                border-right: 7px solid $color-extra;
            }
        &:hover {
            border-left: 10px solid $color-main;
            }
        }
    }
    &__content {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

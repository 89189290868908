.top {
    position: relative;
    @include mq($until: lg) {
        padding-right: 0;
        &__content {
            overflow: hidden;
            position: relative;
        }
    }
    .txtimg {
        column-gap: 5vw;
        @include mq($until: md) {
            row-gap: 60px;
        }
        &__image {
            min-width: 55%;
            @include mq($until: 1400px) {
                img {
                    aspect-ratio: 4/3;
                    object-fit: cover;
                }
            }
            @include mq($until: lg) {
                min-width: 50%;
                img {
                    aspect-ratio: 13/12;
                }
            }
            @include mq($until: md) {
                img {
                    aspect-ratio: 16/9;
                }
            }
        }
        &__content {
            max-width: 35vw;
            padding-bottom: 80px!important;
            @include mq($until: lg) {
                padding-bottom: 40px!important;
                padding-right: 50px;
                max-width: unset;
            }
            @include mq($until: md) {
                padding-left: 50px;
            }
            @include mq($until: sm) {
                padding-left: 30px;
                padding-right: 30px;
            }
            @include mq($until: xs) {
                padding-left: 20px;
                padding-right: 20px;
            }
            h1 {
                font-size: rem(45px);
                line-height: rem(45px);
                font-weight: 600;
                margin-bottom: 60px;
                @include mq($until: 1400px) {
                    font-size: rem(35px);
                    line-height: rem(35px);
                    margin-bottom: 30px;
                }
                @include mq($until: md) {
                    font-size: rem(25px);
                    line-height: rem(25px);
                }
            }
        }
    }
    &__bg {
        position: absolute;
        top: -160px;
        margin-left: auto;
        width: 65vw;
        right: 0;
        z-index: -1;
        @include mq($until: 1400px) {
            width: 72vw;
        }
        @include mq($until: md) {
            width: 50%;
            top: 0;
            display: none;
        }
        img {
            width: 100%;
            height: auto;
            @include mq($until: 1400px) {
                aspect-ratio: 4/3;
                object-fit: cover;
            }
            @include mq($until: lg) {
                aspect-ratio: 14/12;
            }
        }
    }
    &__links {
        width: 65%;
        margin: -60px 0 0 auto;
        background-color: $color-main;
        @include mq($until: 1400px) {
            width: 72%;
        }
        @include mq($until: lg) {
            width: calc(100% - 100px);
            margin: -30px auto 0 auto;
        }
        @include mq($until: md) {
            margin: 20px auto 0 auto;
        }
        @include mq($until: sm) {
            width: calc(100% - 60px);
        }
        @include mq($until: xs) {
            width: 100%;
        }
        .row {
            flex-wrap: nowrap;
            display: flex;
            margin: 0;
            @include mq($until: md) {
                flex-direction: column;
                text-align: center;
            }    
            div {
                width: unset;
                flex: 1;
                margin: 0;
                max-width: unset;
                padding: 0;
                display: flex;
                position: relative;
                > * {
                    display: flex;
                    width: 100%;
                }
                &:after {
                    content: '';
                    width: 1px;
                    height: 50%;
                    top: 50%;
                    position: absolute;
                    right: 0px;
                    background-color: rgba(255, 255, 255, 0.1);
                    transform: translateY(-50%);
                    @include mq($until: md) {
                        width: 80px;
                        height: 1px;
                        transform: translateX(50%);
                        top: unset;
                        bottom: 0;
                        right: 50%;
                    }        
                }
                &:last-of-type:after{
                    display: none;
                }
            }
        }
        a {
            color: #fff;
            background-color: $color-main;
            transition: ease 0.3s all;
            flex-grow: 1;
            padding: 50px 50px;
            font-size: rem(20px);
            font-weight: 600;
            align-items: center;
            display: flex;
            justify-content: center;
            @include mq($until: 1400px) {
                // font-size: rem(18px);
                padding: 40px;
            }
            @include mq($until: lg) {
                padding: 30px;
                justify-content: center;
            }
            &:hover {
                color: $paragraph-color;
                background-color: $color-extra;
                transform: scale(1.1);
                z-index: 1;
            }
        }
    }
}

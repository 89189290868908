.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 160px;
  z-index: 1001;
  @include mq($until: lg) {
    background-color: #fff;
    min-height: 120px;
  }
  @include mq($until: md) {
    background: unset;
  }
  &--absolute {
    position: absolute;
    width: 100%;
    border-bottom: none;
    background: transparent;
    .header__menuLink {
      color: #fff;
    }
    .header__submenu .header__menuLink {
      color: #fff;
      &:active, &--active, &--open, &:hover {
        color: $color-main;
      }
    }
  }

  &--fixed {
    transition: background-color .3s ease-in-out;
    &.sticky {
      position: fixed;
      width: 100%;
      background-color: $color-header;
      .header__menuLink {
        color: #000;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
      .header__submenu .header__menuLink {
        color: #fff;
        &:active, &--active, &--open, &:hover {
          color: $color-main;
        }
      }
    }
  }
  &__logo {
    position: relative;
    z-index: 2;
    img {
      height: auto;
      @include mq($until: 1400px) {
        width: 200px;
      }
      @include mq($until: lg) {
        // width: 13vw;
      }
      @include mq($until: md) {
        width: 200px;
      }
    }
  }
  &__right {
    min-width: 65vw;
    @include mq($until: 1400px) {
      padding-left: 0;
    }
    @include mq($until: md) {
      min-width: unset;
      padding-left: 20px;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $paragraph-color;
    padding: 15px;
    display: block;
    font-weight: 400;
    font-size: rem(20px);
    white-space: nowrap;
    @include mq($until: 1280px) {
      font-size: rem(19px);
    }
    @include mq($until: lg) {
      font-size: rem(18px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
    &--open, &:hover {
      color: #fff;
      &:before {
        height: 100%!important;
      }
    } 
  }
}

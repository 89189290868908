.icons-set {
  display: grid;
  gap: 20px;
  justify-items: stretch;
  &__item {
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;
    height: 100%;
    margin: 0;
    transition: ease 0.3s all;
    overflow: hidden;
    &:hover {
      border-color: $color-main;
    }
    .image {
      width: 100%;
      z-index: 1;
      &[data-hover*='filter'] img {
        filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
      }
      &[data-hover*='filter-extra'] img {
        filter: opacity(0.8) drop-shadow(0 0 0 $color-extra) contrast(250%) contrast(190%) brightness(110%);
      }
      img {
        transition: ease 0.3s background-color;
        height: auto;
      }
      svg {
        width: 60px;
        height: auto;
        fill: $color-main;
        line {
          stroke: $color-main;
        }
      }
    }
    p {
      font-size: rem(18px);
      margin-bottom: 0;
      transition: ease 0.3s color;
    }
    span {
      font-weight: 400;
      margin-top: 10px;
      transition: ease 0.3s color;
      display: block;
    }
    .button {
      margin-top: 15px;
    }
    &[data-hover*='overlay']:hover {
      img {
        background-color: #fff;
      }
      p, span {
        color: #fff;
      }
    }
  }
  &__item-content {
    position: relative;
    z-index: 1;
  }
  &__item-text {
      font-weight: 600;
      font-size: rem(16px);
  }
  @include mq($until: md) {
    &__item {
      img {
        //width: 60px;
      }
    }
    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-left .icons-set__item {
  flex-direction: row;
  align-items: center;
  .image {
    width: auto;
  }
  .icons-set__item-content {
    flex: 1;
  }
}
.icons-center .icons-set__item {
  align-items: center;
  //justify-content: center;
  text-align: center;
  justify-content: flex-start;

}

.icons-right .icons-set__item {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .image {
    width: auto;
    order: 1;
  }
  .icons-set__item-content {
    flex: 1;
    text-align: right;
  }
}


.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: xxl) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include mq($until: 1400px) {
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include mq($until: sm) {
    &--6 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include mq($until: xs) {
    &--8, &--6, &--4, &--3, &--2 {
      grid-template-columns: 1fr;
    }
  }
}


.flipbox {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  aspect-ratio: 1;
  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  &:hover {
    .flipbox-inner {
      transform: rotateY(180deg);
    }
  }
  &-front, &-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    justify-content: center!important;

  }
  &-back {
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 10px;
    background-color: $color-main;
    padding: 20px;
    text-align: center;
    p, span {
      color: #fff;
    }
  }
}

.news-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    &--promo{
        margin-bottom: 30px;
    }
    &__header-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__header{
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
    &__link{
        color: $color-main;
    }
}
.postepowania-list {
    .catalog-card__content {
        row-gap: 0;
        justify-content: flex-start;
    }
    .catalog-card__head {
        font-weight: bold;
        font-size: rem(17px);
        margin-bottom: 10px;
    }
    .catalog-card__excerpt {
        margin-bottom: 0;
    }
}

.info {
    padding-top: 100px;
    padding-bottom: 100px;
    @include mq($until: md) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    &--przetargi {
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
    }
    &--news {
        background-size: cover;
        position: relative;
        .info__content {
            position: relative;
            z-index: 1;
        }
        .divider {
            z-index: 0;
        }
    }
    &--contact {
        display: flex;
        position: relative;
        padding-bottom: 0;
        @include mq($until: md) {
            flex-direction: column-reverse;
            padding-top: 0;
            .contact-data {
                grid-template-columns: auto auto auto;
                margin-bottom: 60px;
            }
        }
        @include mq($until: sm) {
            .contact-data {
                grid-template-columns: 1fr;
            }
        }
        .info__content {
            max-width: 35vw;
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 70px;
            @include mq($until: lg) {
                max-width: unset;
                width: 40%;
            }
            @include mq($until: md) {
                width: 100%;
            }    
            a:hover {
                color: $color-extra;
            }
            .logo img {
                height: auto;
            }
        }
    }
    &__map {
        width: 100%;
        margin-bottom: -100px;
        z-index: 1;
        @include mq($until: lg) {
            width: 60%;
        }
        @include mq($until: md) {
            width: 100%;
            margin-bottom: 60px;
        }
        .leaflet-tile-container {
            filter: brightness(0.9) contrast(1.2);
        }
        .leaflet-bar {
            border: unset;
            a {
                border-radius: 0;
                transition: ease 0.3s all;
                border-bottom: unset;
                &:hover, &:focus {
                    background-color: $color-extra;
                }
            }
        }
    }
    &__bg {
        min-width: 60vw;
        height: 36vw;
        position: absolute;
        z-index: -1;
        left: 0px;
        top: 0;
        @include mq($until: md) {
            display: none;
        }
    }
    &__content {
        h2 {
            text-align: center;
            @include mq($until: md) {
                display: flex;
                flex-direction: column;
                margin: 0;
                align-items: center;
                font-size: rem(25px);
            }
            a {
                font-weight: 400;
                font-size: rem(18px);
                text-decoration: underline;
                padding: 0 20px;
                display: inline-block;
                font-style: italic;
                opacity: 0.8;
                &:hover {
                    opacity: 1;
                }
            }
        }
        .przetargi-list {
            max-width: 50%;
            margin-top: 80px;
            @include mq($until: md) {
                max-width: 100%;
                margin-top: 60px;
                .catalog-card {
                    background-color: rgba(239, 239, 239, 0.9);
                    backdrop-filter: blur(10px);
                }
                .catalog-card__content {
                    background-color: unset;
                }
            }
        }
        .news-list {
            margin-top: 80px;
            @include mq($until: md) {
                margin-top: 60px;
                grid-template-columns: 1fr;
            }
            .catalog-card {
                &:hover {
                    background-color: $color-main;
                    h3, p {
                        color: #fff;
                    }
                }
                &__content {
                    background-color: unset;
                }
            }
        }
    }
}

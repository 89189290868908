.przetarg {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    &__main.inline {
        display: flex;
        gap: 8%;
        & > div {
            width: 46%;
        }
    }
    &__title {
        margin: 0;
    }
    &__summary {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        background-color: $border-color;
        padding: 10px 0;

        &--single {
            padding: 10px 20px;
            border-radius: 10px;
            display: flex;
            column-gap: 10px;
        }
        &.block .przetarg__summary--single {
            flex-direction: column;
            align-items: center;
        }
    }
    &__date {

    }
    &__categories {

        &--single {
            span {
                background-color: $color-main;
                padding: 5px 10px;
                color: #fff;
                text-transform: uppercase;
                font-size: 13px;
                transition: ease 0.3s all;
            }
            &:hover span {
                background-color: $color-extra;
            }
        }
    }
    &__price {

    }
    &__icon {
        svg {
            width: 20px;
            height: auto;
        }
    }
    &__label {
        font-weight: 600;
    }
    .contact-data {
        grid-gap: 10px;
        &__icon {
            background-color: $color-main;
            padding: 10px;
            svg {
                fill: #fff;
            }
        }
    }
}

.features {
    position: relative;
    display: flex;
    &__content {
        padding-top: 100px;
        padding-bottom: 40px;
        max-width: 65vw;
        position: relative;
        z-index: 1;
        background-color: $color-main;
        @include mq($until: 1400px) {
            max-width: unset;
        }
        @include mq($until: lg) {
            max-width: 100%;
            background-color: rgba($color-main, 0.9);
        }
        @include mq($until: md) {
            padding-top: 60px;
        }
        h2 {
            color: #fff;
            @include mq($until: md) {
                font-size: rem(25px);
                text-align: center;
            }
        }
        p {
            color: #fff;
            @include mq($until: md) {
                text-align: center;
            }
        }
        .icons-set {
            margin-top: 80px;
            @include mq($until: md) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            @include mq($until: xs) {
                grid-template-columns: 1fr 1fr;
                overflow: hidden;
            }
            &__item {
                padding-bottom: 30px;
                @include mq($until: xs) {
                    &:nth-child(1){
                        overflow: unset;
                        &:before {
                            content: '';
                            width: 100vw;
                            height: 1px;
                            background-color: rgba(255, 255, 255, 0.1);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }
                &:hover .image svg {
                    fill: $color-extra;
                }
                .image svg {
                    fill: #fff;
                    height: 60px;
                    transition: ease 0.3s all;
                }
            }
        }
        &-contact {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            display: flex;
            @include mq($until: xs) {
                flex-direction: column;
            }
            a {
                color: #fff;
                display: flex;
                align-items: center;
                column-gap: 15px;
                padding: 30px;
                @include mq($until: 1400px) {
                    width: 100%;
                    padding: 20px 30px;
                }
                @include mq($until: lg) {
                    width: unset;
                    background-color: unset;
                }
                @include mq($until: sm) {
                    width: 100%;
                }
                &:hover {
                    color: $color-main;
                    svg {
                        fill: $color-main;
                    }
                }
            }
            svg {
                fill: #fff;
                width: 30px;
                height: 30px;
            }
        }
    }
    &__image {
        background-size: cover;
        background-position: center right;
        flex-grow: 1;
        @include mq($until: 1400px) {
            min-width: 500px;
        }
        @include mq($until: lg) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
